import React, { CSSProperties, isValidElement } from 'react';
import { Flex } from 'components/common/styled/common-styled';
import { P } from 'components/theme/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { type ModalSize } from './Modal.styles';
import * as SC from './Modal.styles';

interface IModalProps {
  onClose?: () => void;
  content: React.ReactNode;
  isClosable: boolean;
  title?: string;
  isVisible: boolean;
  isDarkMode?: boolean;
  style?: CSSProperties;
  disableDismissOnClickOutside?: boolean;
  size?: ModalSize;
  miniCloseButton?: boolean;
  gap?: number;
}

const Modal: React.FC<IModalProps> = ({
  onClose,
  content,
  isClosable,
  title,
  isVisible,
  isDarkMode = false,
  style = {},
  disableDismissOnClickOutside = false,
  size = 'small',
  miniCloseButton = false,
  gap = 36,
}) => {
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }

  return (
    <SC.Overlay
      onClick={(e) => {
        const element = e.target as HTMLDivElement;
        if (
          element?.classList.contains('modal-overlay') &&
          (isClosable || miniCloseButton) &&
          !disableDismissOnClickOutside
        ) {
          onClose?.();
        }
      }}
      className="modal-overlay"
    >
      <SC.OverlayContent
        className="modal-content"
        size={size}
        isDarkMode={isDarkMode}
        style={style}
      >
        <Flex
          direction="column"
          alignItem="flex-start"
          gap={gap}
          style={{ position: 'relative' }}
        >
          {miniCloseButton && (
            <Flex style={{ position: 'absolute', top: -5, right: 7 }}>
              <SC.OverlayHeader onClick={onClose}>
                <SC.Close role="button" aria-label={t('closeModal')} />
              </SC.OverlayHeader>
            </Flex>
          )}
          {title || isClosable ? (
            <SC.HeaderWrapper direction="row" alignItem="flex-start">
              {title ? (
                <SC.ModalTitle className="modal-title">{title}</SC.ModalTitle>
              ) : null}
              {isClosable && (
                <SC.OverlayHeader onClick={onClose}>
                  <SC.Close role="button" aria-label={t('closeModal')} />
                </SC.OverlayHeader>
              )}
            </SC.HeaderWrapper>
          ) : null}
          <SC.ContentBody>
            {isValidElement(content) ? content : <P>{content}</P>}
          </SC.ContentBody>
        </Flex>
      </SC.OverlayContent>
    </SC.Overlay>
  );
};

export default Modal;
