import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ResponsiveFlex } from 'components/common/styled/common-styled';
import {
  CollectionTitle,
  NonMappedResourceGroupCard,
} from 'features/Reserve/components';
import { DateSelector } from 'components/DatePicker/DateSelector';
import moment from 'moment';
import { Route, useRouteMatch } from 'react-router-dom';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import useAvailableDates from 'features/Reserve/hooks/useAvailableDates';
import { DATE_FORMAT } from 'utils';
import { tabletBreakpoint, useIsMobile } from 'WindowDimensionProvider';
import { NonMappedResourceGroupPage } from 'features/Reserve/pages/ResourceGroupPage/NonMappedResourceGroupPage';
import { filterSessionsByGroup } from 'features/Reserve/utils/getSessionsForGroups';
import { BottomSheetDatePicker } from 'components/BottomDrawer';

export const NonMappedCollectionPage: React.FC = () => {
  const isMobileView = useIsMobile();
  const { t: translate } = useTranslation();
  const match = useRouteMatch();

  const {
    collection,
    sessions,
    date,
    latest,
    setResourceBookingSessionParams,
    isLoadingSessions,
    cancellationPolicy,
  } = useResourceBookingSession();

  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates, isLoadingAvailableDates } = useAvailableDates({
    from: dateForCalendarAvailability.startOf('month').format(DATE_FORMAT),
    to: dateForCalendarAvailability.endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    latest,
  });

  const sessionsByGroup = useMemo(
    () =>
      Object.fromEntries(
        collection?.resourceGroups?.map((group) => [
          group.id,
          filterSessionsByGroup(sessions, group),
        ]) || [],
      ),
    [collection?.resourceGroups, sessions],
  );

  const handleDateChange = useCallback(
    (newDate: string) => {
      setResourceBookingSessionParams({
        date: newDate,
      });
    },
    [setResourceBookingSessionParams],
  );

  const onReserveClick = useCallback(
    (groupId: string) => {
      setResourceBookingSessionParams({
        groupId,
      });
    },
    [setResourceBookingSessionParams],
  );

  if (!collection) {
    return <h1>{translate('noCollectionFound')}</h1>;
  }

  return (
    <CollectionDetailsContainer gap="36px">
      <ResponsiveFlex
        flex={1}
        breakpoint={tabletBreakpoint}
        justifyContent="space-between"
        mobileOverride={{
          direction: 'column',
          alignItem: 'flex-start',
          justifyContent: 'stretch',
          gap: 12,
        }}
      >
        <CollectionTitle allowMultipleLines>{collection.title}</CollectionTitle>
        {isMobileView ? (
          <BottomSheetDatePicker
            value={date || ''}
            availableDates={availableDates}
            onMonthChange={setDateForCalendarAvailability}
            onChange={(option) => {
              if (option) {
                handleDateChange(moment(option as Date).format(DATE_FORMAT));
              }
            }}
          />
        ) : (
          <DateSelector
            maxWidth={isMobileView ? '100vw' : '240px'}
            loading={isLoadingAvailableDates}
            date={date || ''}
            availableDates={availableDates}
            onMonthChange={setDateForCalendarAvailability}
            onChange={handleDateChange}
          />
        )}
      </ResponsiveFlex>
      <ResponsiveFlex
        direction="column"
        alignItem="flex-start"
        justifyContent="flex-start"
        width="100%"
        gap={36}
      >
        {collection.resourceGroups?.map((group) => {
          const groupSessions = sessionsByGroup[group.id] || [];
          return (
            <NonMappedResourceGroupCard
              sessions={groupSessions}
              group={group}
              key={group.id}
              onReserveClick={onReserveClick}
              isLoading={isLoadingSessions}
              cancellationPolicy={cancellationPolicy}
            />
          );
        })}
      </ResponsiveFlex>
      {isMobileView && (
        <Route
          exact
          path={`${match.path}/group/:groupId`}
          component={NonMappedResourceGroupPage}
        />
      )}
    </CollectionDetailsContainer>
  );
};

const CollectionDetailsContainer = styled.div<{ gap?: CSSProperties['gap'] }>`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: ${(props) => props.gap || '24px'};
`;
