import { useEffect, useState } from 'react';
import { get } from 'utils/axios';
import { PublicGetListingEndpoint, ListingVersion } from '@kouto/types';
import { Listing } from 'types/listings';

enum states {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

const addNewIdState = (newIds: string[]) => (prev: Record<string, states>) => {
  const updated = { ...prev };
  newIds.forEach((id) => {
    updated[id] = updated[id] || states.PENDING;
  });
  return updated;
};

const useListingsByIds = (ids: string[]) => {
  const [listings, setListings] = useState<Record<string, Listing>>({});
  const [listingsState, setListingsState] = useState<Record<string, states>>(
    {},
  );
  const [idsToFetch, setIdsToFetch] = useState<string[]>([]);
  const uniqueIds = Array.from(new Set(ids));

  useEffect(() => {
    setIdsToFetch(
      uniqueIds.filter((id) => typeof listings[id] === 'undefined'),
    );
  }, [JSON.stringify(uniqueIds), listings]);

  useEffect(() => {
    setListingsState(addNewIdState(idsToFetch));

    idsToFetch.forEach((id) => {
      const url = PublicGetListingEndpoint.url({
        listingId: id,
        query: {
          version: ListingVersion.PUBLISHED,
        },
      });

      get<{ data: Listing }>(url)
        .then((response) => {
          setListings((prev) => ({
            ...prev,
            [id]: response.data,
          }));
          setListingsState((prev) => ({
            ...prev,
            [id]: states.SUCCESS,
          }));
        })
        .catch(() => {
          setListingsState((prev) => ({
            ...prev,
            [id]: states.ERROR,
          }));
        });
    });
  }, [idsToFetch]);

  return {
    listings,
    isLoading:
      (Object.values(listingsState).length === 0 && uniqueIds.length > 0) ||
      Object.values(listingsState).some((state) => state === states.PENDING),
    error: Object.values(listingsState).some((state) => state === states.ERROR),
  };
};

export default useListingsByIds;
