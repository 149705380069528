import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import get from 'lodash/get';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { fetchBrandSettings, fetchBrandIntegrations } from 'actions/brand';
import { setThemeColor, setThemeFont, setThemeMode } from 'actions/theme';
import { FETCH_SETTINGS_FAILURE, THEMES } from 'types/brand';
import { STAGE_LIVE } from 'types/app';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import BookingConfirmation from 'components/Booking/BookingConfirmation/BookingConfirmation';
import Experiences from 'components/Experiences';
import ExperienceView from 'components/ExperienceView';
import { capitalize, serializeParams, getContrastShades } from 'utils';
import Version from 'components/Version';
import ToggleFeature from 'components/ToggleFeature';
import ExperiencesCalendar from 'components/ExperiencesCalendar';
import useSearchQueryParams from 'hooks/use-search-params';
import ExperienceViewWrapper from 'components/ExperienceView/ExperienceViewWrapper';
import MainLandingPage from 'features/LandingPage/MainLandingPage';
import { generateVariableCss } from 'theme/themeUtils';
import { generateShades } from 'theme/themePalette';
import {
  ParticipantsForm,
  WaitlistForm,
  Checkout,
  JoinWaitlist,
} from 'features/ShoppingCart/pages';
import {
  loadPersistedCartItems,
  noNeedToLoadPersistedCartItems,
} from 'actions/cart';
import { FeatureFlagWrapper } from 'components/FeatureFlagWrapper';
import Toast from 'components/Toast/Toast';
import { getLSItem } from 'utils/localStorage';
import { CART_LS_KEY } from 'types/cart';
import { useSentryContext } from 'hooks/use-sentry-context';
import PreviewBanner from 'components/PreviewBanner';
import { useHandleSocialUrl } from 'utils/useHandleSocialUrl';
import { useEmbedConfig } from 'features/EmbedConfig';
import { getHomeComponent } from 'utils/getHomeComponent';
import { analyticsManager } from 'features/analytics';
import { ANALYTICS_PROPERTY, AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import { useIsBrandV2 } from 'hooks/useIsV2Brand';
import { useGeneralAnalyticsTracker } from './hooks/useGeneralAnalyticsTracker';
import { ReserveCollection } from './features/Reserve/ReserveCollection';
import SelectAddOnsPage from './features/AddOns/SelectAddOnsPage';
import EventPage from './features/Events/EventPage';
import GuestCancellationPage from './features/GuestCancellation/GuestCancellationPage';
import GuestCancellationConfirmationPage from './features/GuestCancellation/GuestCancellationConfirmationPage';
import EventClaimTicketsError from './features/Events/pages/EventClaimTicketsError';
import EventClaimTickets from './features/Events/pages/EventClaimTickets';

const environment = process.env.KOUTO_APP_ENV || 'development';
Sentry.init({
  environment,
  enabled: environment !== 'development',
  dsn: process.env.SENTRY_DSN,
  allowUrls: [/https:\/\/storage\.googleapis\.com\/embed-script\.kouto\.co/],
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
    }),
  ],
});

const GlobalStyle = createGlobalStyle` 
  #kouto-embed-root {    
    background-color: ${({ darkMode }) =>
      darkMode ? 'var(--way-palette-black-100)' : 'inherit'};
    text-align:left;

    h1,h2,h3,h4,h5,h6{
      text-align: left;
    }
    
    ${({ themeVariables }) => themeVariables}
  }
  body {
    margin: 0;
    padding: 0;
  }

  *:before,*:after {
    box-sizing: border-box;
  }

  .site-wrapper{
    overflow: visible !important;
  }
  /* Specific fixes for Tourist Site */
  #s_page #kouto-embed-root ul{
    margin-left: 0;
  }
  #s_page #kouto-embed-root li{
    padding-top:0;
    text-align:center;
  }

  body.way-modal-open {
    height: 100%;
    overflow: hidden;
  }

  svg * {
    transition: fill 0.2s, stroke 0.2s;
  }
`;

const CustomScriptStyle = createGlobalStyle` 
  ${({ customStyle }) => customStyle};
`;

const persistedCartState = getLSItem(CART_LS_KEY);

export default function App() {
  useSentryContext();
  const { theme, brand } = useAppState();
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const [brandError, setBrandError] = useState(null);
  const [themeVariablesCss, setThemeVariablesCss] = useState('');

  useGeneralAnalyticsTracker();

  useHandleSocialUrl();

  const embedConfig = useEmbedConfig();
  const { searchParams } = useSearchQueryParams();

  const isV2Brand = useIsBrandV2();

  const newLandingPageFeatureFlag = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.ENABLE_NEW_LANDING_PAGE,
  );

  const newLandingPageEnabled =
    searchParams.v2 === 'true' || newLandingPageFeatureFlag;

  useEffect(() => {
    if (brand?.fetchSettingsStatus !== 'success') return;

    const root = document.getElementById('kouto-embed-root');

    const { classList } = root || {};

    if (classList?.contains('kouto-v1') || classList?.contains('kouto-v2')) {
      return;
    }

    root?.classList.add(newLandingPageEnabled ? 'kouto-v2' : 'kouto-v1');
  }, [brand?.fetchSettingsStatus, newLandingPageEnabled, searchParams?.v2]);

  const HomeComponent = useMemo(
    () =>
      getHomeComponent({
        homeComponent: embedConfig.home,
        newLandingPageEnabled,
        isV2Brand,
      }),
    [embedConfig.home, newLandingPageEnabled, isV2Brand],
  );

  const isAppLive = process.env.KOUTO_STAGE === STAGE_LIVE;

  const firstFetch = async () => {
    const response = await dispatch(fetchBrandSettings(brandId));
    if (response.type === FETCH_SETTINGS_FAILURE) {
      setBrandError('Error Fetching Brand');
      return;
    }
    const {
      payload: { settings },
    } = response;

    if (persistedCartState[brandId]) {
      // NOTE: old local storage states are (before new stripe element):
      //  {
      //    [brandId]: [array of cartItems]
      //  }
      // New local storage states are:
      //  {
      //    [brandId]: {
      //      cartId: string,
      //      cartItems: [array of cartItems]
      //    }
      //  }
      if (persistedCartState[brandId].cartId) {
        await dispatch(
          loadPersistedCartItems({
            cartId: persistedCartState[brandId].cartId,
            cartItems: persistedCartState[brandId].cartItems,
          }),
        );
      } else {
        await dispatch(
          loadPersistedCartItems({
            cartId: uuidv4(),
            cartItems: persistedCartState[brandId],
          }),
        );
      }
    } else {
      await dispatch(noNeedToLoadPersistedCartItems());
    }
    await dispatch(fetchBrandIntegrations(brandId));

    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.CurrencyCode]: get(settings, 'currency'),
    });

    initTheme(settings);
  };

  const initTheme = (settings) => {
    const primaryFont = get(settings, 'designElement.primaryFont');
    const secondaryFont = get(settings, 'designElement.secondaryFont');
    const primaryColor =
      get(settings, 'designElement.primaryColor') || theme.colors.primaryColor;
    const secondaryColor =
      get(settings, 'designElement.secondaryColor') ||
      theme.colors.secondaryColor;
    const buttonColor =
      get(settings, 'designElement.buttonColor') || theme.colors.buttonColor;
    const brandThemeMode =
      get(settings, 'designElement.theme') || THEMES.DEFAULT;
    const designElementColors = {
      primaryColorShades: generateShades(primaryColor),
      secondaryColorShades: generateShades(secondaryColor),
      buttonColorShades: generateShades(buttonColor),
    };
    const themeColors = {
      ...(brandThemeMode === THEMES.DARK ? theme.darkColors : theme.colors),
      ...designElementColors,
    };
    delete themeColors.primaryColor;
    delete themeColors.secondaryColor;
    delete themeColors.buttonColor;
    const themeDesign = {
      ...theme.design,
      ...(brandThemeMode === THEMES.DARK ? theme.darkDesign : {}),
    };

    themeColors.primaryColorContrastShades = getContrastShades(primaryColor);
    themeColors.secondaryColorContrastShades =
      getContrastShades(secondaryColor);
    themeColors.buttonColorContrastShades = getContrastShades(buttonColor);

    dispatch(setThemeMode(brandThemeMode));
    dispatch(setThemeColor(themeColors));

    setThemeVariablesCss(
      generateVariableCss({
        'way-colors': themeColors,
        'way-palette': theme.palette,
        'way-design': themeDesign,
      }),
    );

    dispatch(
      setThemeFont({
        primaryFont: primaryFont
          ? `${capitalize(primaryFont)}, sans-serif`
          : '',
        secondaryFont: secondaryFont
          ? `${capitalize(secondaryFont)}, sans-serif`
          : '',
      }),
    );
  };

  useEffect(() => {
    firstFetch();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(embedConfig.language || 'en');
  }, [embedConfig.language]);

  if (brandError) {
    console.error('Brand Loading Error', brandError);
    return (
      <div>
        <div>
          <h3
            style={{
              color: 'var(--way-palette-charcoal-100)',
            }}
          >
            Error Loading Brand
          </h3>
          <div
            style={{
              padding: '20px',
              backgroundColor: '#eee',
            }}
          >
            <h4>Checklist</h4>
            <ol>
              <li>Whitelist your domain in Way admin dashboard.</li>
              <li>Make sure you use the correct brandId.</li>
              <li>
                If you have CSP policies set in your backend, whitelist Way
                Script connect sources in the policies.
              </li>
              <code>
                <ul>
                  <li>*.kouto.co</li>
                  <li>*.stripe.com</li>
                  <li>*.imagekit.io</li>
                  <li>*.googleapis.com</li>
                </ul>
              </code>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  /* eslint-disable react/prop-types */
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle
          themeVariables={themeVariablesCss}
          darkMode={
            get(brand, 'settings.designElement.theme') === THEMES.DARK &&
            !isAppLive
          }
        />
        <Toast />
        {get(brand, 'settings.customStyle') && (
          <CustomScriptStyle customStyle={get(brand, 'settings.customStyle')} />
        )}
        <PreviewBanner />
        <main>
          <Switch>
            <Route exact path="/e">
              {typeof newLandingPageEnabled !== 'undefined' &&
                newLandingPageEnabled && <MainLandingPage />}
              {typeof newLandingPageEnabled !== 'undefined' &&
                !newLandingPageEnabled && <Experiences asHomePage />}
            </Route>
            <Route
              exact
              path="/experiences"
              render={() => (
                <Redirect
                  to={{
                    pathname: '/e',
                    search: serializeParams(searchParams),
                  }}
                />
              )}
            />

            <Route
              path="/collection/:collectionId"
              component={ReserveCollection}
            />

            <Route path="/addons/:cartItemId" component={SelectAddOnsPage} />

            <Route
              path="/carts/:cartId/guest-cancellation"
              component={GuestCancellationPage}
              exact
            />
            <Route
              path="/carts/:cartId/guest-cancellation/booking/:bookingId"
              component={GuestCancellationPage}
              exact
            />
            <Route
              path="/carts/:cartId/guest-cancellation/confirmation"
              component={GuestCancellationConfirmationPage}
              exact
            />

            <Route
              exact
              path="/event/:eventId/claim/:waitlistEntryId"
              component={EventClaimTickets}
            />
            <Route path="/event/:eventId" component={EventPage} />

            <Route
              exact
              path="*/experiences/:experienceId/booking"
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `/e/${props.match.params.experienceId}/booking`,
                    search: serializeParams(searchParams),
                  }}
                />
              )}
            />
            <Route
              exact
              path="/e/booking/confirmation"
              component={BookingConfirmation}
            />
            <Route
              exact
              path="/experiences/:experienceId"
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `/e/${props.match.params.experienceId}`,
                    search: serializeParams(searchParams),
                  }}
                />
              )}
            />
            <Route exact path="/e/:experienceId">
              <ExperienceViewWrapper>
                <ExperienceView />
              </ExperienceViewWrapper>
            </Route>
            <Route exact path="/calendar">
              <ExperiencesCalendar />
            </Route>
            <Route
              exact
              path="/e/:experienceId/participants/:cartItemId"
              render={() => (
                <FeatureFlagWrapper featureKey="shoppingCart">
                  <ParticipantsForm />
                </FeatureFlagWrapper>
              )}
            />
            <Route
              exact
              path="/e/:experienceId/waitlist"
              render={() => <WaitlistForm />}
            />
            <Route
              exact
              path="/checkout"
              render={() => (
                <FeatureFlagWrapper featureKey="shoppingCart">
                  <Checkout />
                </FeatureFlagWrapper>
              )}
            />
            <Route
              exact
              path="/join-waitlist"
              render={() => <JoinWaitlist />}
            />
            <Route
              exact
              path="/claim-tickets-error"
              render={() => <EventClaimTicketsError />}
            />
            <Route
              exact
              path="/landing/:product/search"
              component={HomeComponent}
            />
            <Route exact path="/landing/:product" component={HomeComponent} />
            <Route exact path="/search" component={HomeComponent} />
            <Route exact component={HomeComponent} />
          </Switch>
        </main>
        <footer>
          <ToggleFeature featureId="scriptVersion">
            <Version />
          </ToggleFeature>
        </footer>
      </ThemeProvider>
    </>
  );
}
